import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { map as _map } from 'lodash';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import './about-logos.scss';

import logo1 from '@images/logos/logo-1.png';
import logo2 from '@images/logos/logo-2.png';
import logo3 from '@images/logos/logo-3.png';
import logo4 from '@images/logos/logo-4.png';
import logo5 from '@images/logos/logo-5.png';
import logo6 from '@images/logos/logo-6.png';
import logo7 from '@images/logos/logo-7.png';
import logo8 from '@images/logos/logo-8.png';
import logo9 from '@images/logos/logo-9.png';
import logo10 from '@images/logos/logo-10.png';

const logos = [
    { title: '', image: logo1 },
    { title: '', image: logo2 },
    { title: '', image: logo3 },
    { title: '', image: logo4 },
    { title: 'rossy-cancer-network', image: logo5 },
    { title: '', image: logo10 },
    { title: '', image: logo6 },
    { title: '', image: logo7 },
    { title: '', image: logo8 },
    { title: '', image: logo9 },
];

const AboutLogos = ({ className }) => {
    const { t } = useTranslation();

    return (
        <section className={classNames(className, 'about__logos')}>
            {
                _map(logos, (logo, key) => {
                    const { title = '', image} = logo;
                    return <img src={image} alt={t(`Logo ${title}`)} key={key} className={title} />
                })
            }
        </section>
    );
};


AboutLogos.propTypes = {
    className: PropTypes.string,
};

AboutLogos.defaultProps = {
    className: '',
};

export default AboutLogos;
