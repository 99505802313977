import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './section.scss';

const Section = ({ children, noColumns, className }) => {
    const classes = classNames('section', className);

    const content = noColumns ? children : (
        <div className="col-12">
            {children}
        </div>
    );

    return (
        <section className={classes}>
            <div className="container">
                <div className="row">
                    { content }
                </div>
            </div>
        </section>
    );
};


Section.propTypes = {
    className: PropTypes.string,
    noColumns: PropTypes.bool,
};

Section.defaultProps = {
    className: '',
    noColumns: false,
};

export default Section;
