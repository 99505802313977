import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { secureMap, secureFind } from '@utils/secure-operations';
import { graphql, useStaticQuery } from 'gatsby';
import Expert from '@molecules/expert';


const expertsList = [
    {
        id: 'rosana-faria',
        name: 'Rosana Faria',
        bio: {
            en: 'Rosana Faria is a clinical psychologist in the Dept. of Oncology at St. Mary\'s Hospital. Her research interests focus on improving patient experiences and psychosocial health outcomes for people with cancer and in cancer recovery.',
            fr: `Rosana Faria est psychologue clinicienne au département d’oncologie de l’hôpital St. Mary. Ses champs de recherche se concentrent sur l’amélioration de l’expérience des patients et des conséquences sur la santé psychosociale des patients atteints du cancer.`,
        },
        title: { en: 'psychologist', fr: `psychologue` },
    },
    {
        id: 'janet-steinberg',
        name: 'Janet Steinberg',
        bio: {
            en: `Janet Steinberg holds a Master’s degree in Social Work from McGill University, as well as a post-graduate certificate in Couple and Family Therapy. She works at St. Mary’s Hospital with patients and families affected by cancer, and also has a private practice where she offers individual, couple and family counseling.`,
            fr: `Janet Steinberg est titulaire d’une maîtrise en travail social de l’Université McGill ainsi qu’un certificat d’études supérieures en thérapie familiale et de couple. Elle travaille à l’hôpital St. Mary avec les patients et les familles touchés par le cancer. Elle pratique également dans le secteur privé où elle offre des psychothérapies de couple, individuelles et familiales.`,
        },
        title: { en: 'psychotherapist', fr: `psychothérapeute` },
    },
    {
        id: 'maria-milioto',
        name: 'Maria Milioto',
        bio: {
            en: `Maria Milioto obtained a bachelor's degree in physiotherapy from McGill University in 1995 and an MBA from HEC Montréal in 2003. In 2018, she obtained her certification as a corporate director from Laval University. Her professional background testifies to her passion for the field of interdisciplinary rehabilitation and rehabilitation at work. Ms. Milioto has implemented an innovative return to work cancer program called Vitalité and has participated in research on disability in collaboration with Dr. Michael Sullivan of McGill University. She is a member of the board of directors of the Fédération des cliniques privées de physiothérapie du Québec (FPPPQ), in addition to her role as external vice-president.`,
            fr: `En 1995, Maria Milioto a obtenu un baccalauréat en physiothérapie de l’Université McGill. Puis, en 2003, une maîtrise en administration des affaires (MBA) de HEC Montréal. En 2018, elle a obtenu sa certification des administrateurs de Sociétés de l’Université Laval. Son parcours professionnel témoigne de sa passion pour le domaine de la réadaptation interdisciplinaire et de la réadaptation au travail. Mme Milioto a mis en place un programme novateur sur le retour au travail suite à un cancer appelé Vitalité. Elle a participé à des recherches sur l’incapacité en collaboration avec le Dr Michael Sullivan de l’Université McGill. Elle est membre du conseil d’administration de la Fédération des cliniques privées de physiothérapie du Québec (FPPPQ), en plus de son rôle de vice-présidente aux affaires externes.`,
        },
        title: { en: 'physiotherapist', fr: `physiothérapeute` },
    },
    {
        id: 'tarek-hijal',
        name: 'Tarek Hijal',
        bio: {
            en: `Dr. Hijal is a radiation oncologist at the McGill University Health Centre. His clinical interests include breast cancer, colorectal cancer and hematologic malignancies. His research mainly focuses on decreasing treatment durations in breast cancer radiotherapy.`,
            fr: `Docteur Hijal est radio-oncologue au Centre universitaire de santé McGill. Sa pratique clinique porte entre autres sur les cancers du sein et colorectal, et les maladies malignes hématologiques. Ses recherches se concentrent principalement sur la diminution de la durée des traitements de radiothérapie pour le cancer du sein.`,
        },
        title: { en: 'radiation Oncologist', fr: `radio-oncologue` },
    },
    {
        id: 'joan-zidulka',
        name: 'Joan Zidulka',
        bio: {
            en: `Dr. Zidulka was a hematologist-oncologist at St. Mary’s Hospital and an associate professor in the Departments of Medicine and Oncology at McGill University. As a community-based oncologist she worked with all the common solid and hematological tumors.`,
            fr: `Docteur Zidulka était hémato-oncologue à l’hôpital St. Mary et est professeur associée aux Départements de médecine et d’oncologie de l’Université McGill. Lors de son travail en oncologie, elle traitait toutes les tumeurs solides et hématologiques les plus courantes.`,
        },
        title: { en: 'oncologist (retired)', fr: `oncologue (retraitée)` },
    },
    {
        id: 'mei-lin-yee',
        name: 'Mei-Lin Yee',
        bio: {
            en: `Mei-Lin Yee is currently completing a master's degree in law and health policy, with a specialization in patient rights. She also has almost 30 years experience in labour law and human resources management. As a result of her own cancer diagnosis, Ms. Yee now actively volunteers as a patient rights advocate, where she is able to use her personal and professional experience to their fullest.`,
            fr: `Mei-Lin Yee est candidate à la maîtrise en droit et politiques de la santé avec une spécialisation en droits des patients. Elle a près de 30 ans d’expérience dans le domaine de droits de travail et en gestion de ressources humaines. Suite à son propre diagnostique de cancer, Mme Yee utilise son expertise professionnelle en étant bénévole et ayant le rôle de défenseure des droits des patients.`,
        },
        title: { en: 'patient rights advocate', fr: `défenseur des droits des patients` },
    },
    {
        id: 'sandra-grant',
        name: 'Sandra Grant',
        bio: {
            en: `Sandra Grant is a graduate of the Faculty of Science at McGill University where she majored in Dietetics and Human Nutrition. She has worked at St. Mary’s Hospital for over 20 years and now specializes in cancer care.`,
            fr: `Sandra Grant est diplômée de la Faculté des Sciences de l’Université McGill où elle s’est spécialisée en diététique et nutrition humaine. Elle a travaillé à l’hôpital St. Mary pendant plus de 20 ans et dédie maintenant son expertise au traitement contre le cancer.`,
        },
        title: { en: 'dietitian', fr: `dietéticienne` },
    },
    {
        id: 'karine-gimmig',
        name: 'Karine Gimmig',
        bio: {
            en: `Karine Gimmig received her nursing degree from the Université de Montréal. She has worked as a pivot nurse in cancer care, specializing in breast cancer and palliative care, at St. Mary’s Hospital since 2012.`,
            fr: `Karine Gimmig est diplômée en sciences infirmières de l’Université de Montréal. Elle travaille depuis 2012 en tant qu’infirmière pivot au Centre de cancer spécialisé dans le cancer du sein et des soins palliatifs à l’hôpital St. Mary.`,
        },
        title: { en: 'pivot nurse', fr: `infirmière pivot` },
    },
    {
        id: 'nancy-moscato',
        name: 'Nancy Moscato',
        bio: {
            en: `Nancy Moscato is a clinical social worker. She has been working at the McGill University Health Centre for over 20 years, the past 10 years in radio-oncology. Nancy provides resources, support and psycho-social care to patients, families and caregivers throughout the illness continuum and the recovery process. She has also acted as field placement co-coordinator for the MUHC social service adult sites for more than 8 years.`,
            fr: `Nancy Moscato est travailleuse sociale clinique. Elle travaille au Centre universitaire de santé McGill depuis plus de 20 ans, et au Département de radio-oncologie depuis 10 ans. Mme Moscato fournit des ressources, du soutien et des soins psychosociaux aux patients, aux familles et aux aidants tout au long de la maladie et de la convalescence. Elle est également la coordinatrice de stages pour le département du service social pour adultes du CUSM depuis plus de 8 ans.`,
        },
        title: { en: 'social worker', fr: `travailleuse sociale` },
    },
    {
        id: 'marc-hamel',
        name: 'Marc Hamel',
        bio: {
            en: `Dr. Hamel is the clinical director of the psycho-social oncology program at the McGill University Health Centre, and provides psychological services to oncology patients and their families. His research interests include screening for distress, anxiety and depression, the fear of recurrence, and coping.`,
            fr: `Docteur Hamel est le directeur clinique du Programme d’oncologie psychosociale au Centre universitaire de santé McGill et fournit des services psychosociaux aux patients en oncologie et à leur famille. Ces champs de recherche comprennent le dépistage de la détresse, l’anxiété et la dépression, la peur de la récidive et l’adaptation.`,
        },
        title: { en: 'psychologist', fr: `psychologue` },
    },
];

const expertsImagesQuery = graphql`
query expertsImages {
    allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "experts"}}) {
        edges {
        node {
            name
            childImageSharp {
            fixed(height: 250) {
                base64
                src
                srcSet
                height
                width
            }
            }
        }
        }
    }
}
`;

const ExpertsList = () => {
    const { i18n } = useTranslation();
    const data = useStaticQuery(expertsImagesQuery);
    const expertImages = secureMap(data?.allFile?.edges, ({ node }) => {
        return {
            id: node?.name,
            image: node?.childImageSharp?.fixed,
        }
    });

    const experts = secureMap(expertsList, expert => {
        const expertImage = secureFind(expertImages, ({ id }) => id === expert.id);
        const bio = expert.bio[i18n.language];
        const title = expert.title[i18n.language];

        return {
            ...expert,
            bio,
            title,
            ...expertImage
        }
    });


    return (
        <section className="expertsList">
            {
                secureMap(experts, expert => {
                    const { id, name, title, bio, image } = expert;

                    return (
                        <Expert
                            key={id}
                            id={id}
                            name={name}
                            title={title}
                            bio={bio}
                            image={image}
                        />
                    )
                })
            }
        </section>
    );
};


export default ExpertsList;
