// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-static-about-us-mdx": () => import("./../../../src/content/static/about-us.mdx" /* webpackChunkName: "component---src-content-static-about-us-mdx" */),
  "component---src-content-static-bibliography-mdx": () => import("./../../../src/content/static/bibliography.mdx" /* webpackChunkName: "component---src-content-static-bibliography-mdx" */),
  "component---src-content-static-disclaimer-mdx": () => import("./../../../src/content/static/disclaimer.mdx" /* webpackChunkName: "component---src-content-static-disclaimer-mdx" */),
  "component---src-content-static-feedback-mdx": () => import("./../../../src/content/static/feedback.mdx" /* webpackChunkName: "component---src-content-static-feedback-mdx" */),
  "component---src-content-static-fr-about-us-mdx": () => import("./../../../src/content/static/fr/about-us.mdx" /* webpackChunkName: "component---src-content-static-fr-about-us-mdx" */),
  "component---src-content-static-fr-bibliography-mdx": () => import("./../../../src/content/static/fr/bibliography.mdx" /* webpackChunkName: "component---src-content-static-fr-bibliography-mdx" */),
  "component---src-content-static-fr-disclaimer-mdx": () => import("./../../../src/content/static/fr/disclaimer.mdx" /* webpackChunkName: "component---src-content-static-fr-disclaimer-mdx" */),
  "component---src-content-static-fr-feedback-mdx": () => import("./../../../src/content/static/fr/feedback.mdx" /* webpackChunkName: "component---src-content-static-fr-feedback-mdx" */),
  "component---src-content-static-fr-terms-and-conditions-mdx": () => import("./../../../src/content/static/fr/terms-and-conditions.mdx" /* webpackChunkName: "component---src-content-static-fr-terms-and-conditions-mdx" */),
  "component---src-content-static-terms-and-conditions-mdx": () => import("./../../../src/content/static/terms-and-conditions.mdx" /* webpackChunkName: "component---src-content-static-terms-and-conditions-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-identify-your-needs-js": () => import("./../../../src/pages/identify-your-needs.js" /* webpackChunkName: "component---src-pages-identify-your-needs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-roadmap-pdf-js": () => import("./../../../src/pages/my-roadmap-pdf.js" /* webpackChunkName: "component---src-pages-my-roadmap-pdf-js" */),
  "component---src-pages-questionnaire-js": () => import("./../../../src/pages/questionnaire.js" /* webpackChunkName: "component---src-pages-questionnaire-js" */),
  "component---src-templates-chapter-template-js": () => import("./../../../src/templates/chapter-template.js" /* webpackChunkName: "component---src-templates-chapter-template-js" */)
}

