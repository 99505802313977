const isValidArray = (array) => array && array.length;

const secureMap = (array, callback) => {
    if (!isValidArray(array)) {
        return [];
    }

    return array.map(callback);
};

const secureFind = (array, callback) => {
    if (!isValidArray(array)) {
        return null;
    }

    return array.find(callback);
};

const secureFilter = (array, callback) => {
    if (!isValidArray(array)) {
        return null;
    }

    return array.filter(callback);
};

const secureForEach = (array, callback) => {
    if (!isValidArray(array)) {
        return null;
    }

    return array.forEach(callback);
};

const secureForEachNodes = (array, callback) => {
    if (!isValidArray(array)) {
        return null;
    }

    return Array.prototype.forEach.call(array, callback);
};

export {
    secureFilter,
    secureFilter as _filter,
    secureFind,
    secureFind as _find,
    secureForEach,
    secureForEach as _each,
    secureForEachNodes,
    secureMap,
    secureMap as _map,
}
