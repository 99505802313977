module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4245a6","theme_color":"#4245a6","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af863ee5b7d42cd3779f138ad1228806"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":600,"wrapperStyle":"position: relative","linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Mulish:200,600,800"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"staticContent":"/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js","default":"/Users/diarcastro/clients/cure-foundation/looking-forward/src/templates/static-content-template.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":600,"wrapperStyle":"","linkImagesToOriginal":false}},{"resolve":"/Users/diarcastro/clients/cure-foundation/looking-forward/plugins/parse-links/index.js","options":{}}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/diarcastro/clients/cure-foundation/looking-forward"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/diarcastro/clients/cure-foundation/looking-forward/src/locales","languages":["en","fr"],"defaultLanguage":"en","i18nextOptions":{"ns":["translation","index","iyn","about","roadmap","questionnaire","chapters"],"defaultNS":"translation","interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"},"pages":[{"matchPath":"/:lang?/chapters/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/chapitres/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/(about-us|bibliography|feedback)","getLanguageFromPath":true}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
