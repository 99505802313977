import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Section from '@atoms/section';
import Text from '@atoms/text';

import './expert.scss';


export const Expert = ({id, name, title, image, bio}) => {
    const { t } = useTranslation();

    const translatedTitle = title ? `, ${t(title)}` : '';
    return (
        <Section className="mt-0 mb-8 expert" noColumns={true}>
            <div className="col-md-auto text-center expert__image"><Img fixed={image} /></div>
            <div className="col d-flex flex-column justify-content-center">
                <h3 className="mb-4 font-weight-bold text-center text-md-left expert__name" id={id}>
                    {name}{translatedTitle}
                </h3>
                <Text className="mb-0 expert__bio">{bio}</Text>
            </div>
        </Section>
    );
};


Expert.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    image: PropTypes.object,
    bio: PropTypes.string,
    id: PropTypes.string,
};

Expert.defaultProps = {
    id: '',
}

export default Expert;
