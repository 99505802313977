import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './text.scss';

const COMPONENT_CLASS = 'textBlock';

const Text = ({ children, className, size }) => {
    const classes = classNames(COMPONENT_CLASS, className, size ? `${COMPONENT_CLASS}--${size}` : null);

    const components = {
        strong: <strong />,
        u: <u />,
        i: <i />,
        p: <p />,
        br: <br />,
        span: <span />,
    };

    return (
        <p className={classes}>
            <Trans components={components}>
                {children}
            </Trans>
        </p>
    );
};


Text.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

Text.defaultProps = {
    className: '',
    size: null,
};

export default Text;
